<template>
  <v-container class="mt-2 productContainer">
    <v-row>
        <v-col cols="12">
          <strong>
            <h3>Our Global Distributors</h3>
          </strong>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" md="4" v-for="(item, index) in allDistributors" :key="index" >
           
             <v-card
              class="mx-auto my-12"
              max-width="auto"
            >
              <template slot="progress">
                <v-progress-linear
                  color="deep-purple"
                  height="10"
                  indeterminate
                ></v-progress-linear>
              </template>

              <v-card-title>{{item.companyName}}</v-card-title>

              <v-card-text>
                <v-row
                  align="center"
                  class="mx-0"
                >
                  <v-rating
                    :value="4.5"
                    color="amber"
                    dense
                    half-increments
                    readonly
                    size="14"
                  ></v-rating>

                  <div class="grey--text ms-4">
                    4.5 (413)
                  </div>
                </v-row>

                <div class="my-4 text-subtitle-1">
                  • {{item.User.state}}, {{item.User.country}}
                </div>

                <div>{{item.description}}</div>
              </v-card-text>

              <!-- <v-card-text>
                <v-chip-group
                  active-class="deep-purple accent-4 white--text"
                  column
                >
                  <v-chip>Shop</v-chip>
                </v-chip-group>
              </v-card-text> -->

            </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Distributors",

  data: () => ({
    allDistributors:[],
  }),
  created() {
    this.fetchDistributors();
  },
  methods: {
    fetchDistributors() {
      this.$http
        .get(`${process.env.VUE_APP_URL}distributors`)
        .then((response) => {
          console.log(response);
          this.allDistributors = response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 50px;
}
</style>
